class HomeSlider extends Component {
	constructor(el) {
		super(el);
		this.sliderEl = this.$find('[data-slider]');
		this.slider = null;
		this.sliderEl && this.constructSlider();
	}

	constructSlider() {
		this.slider = new SimpleSlider(this.sliderEl);
	}
}
